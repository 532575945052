import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/about": [16],
		"/billing": [17],
		"/contact": [18],
		"/impacts": [19],
		"/impacts/[id]": [20],
		"/login": [21],
		"/login/google": [22],
		"/login/google/callback": [23],
		"/logout": [24],
		"/me": [25],
		"/privacy": [26],
		"/terms": [27],
		"/workspaces": [28],
		"/[ressourceId]": [4,[2]],
		"/[ressourceId]/activities": [5,[2]],
		"/[ressourceId]/activities/create": [7,[2]],
		"/[ressourceId]/activities/[id]": [6,[2]],
		"/[ressourceId]/dashboard": [8,[2]],
		"/[ressourceId]/dashprint": [9,[2]],
		"/[ressourceId]/services": [10,[2]],
		"/[ressourceId]/services/[id]": [11,[2]],
		"/[ressourceId]/teams": [12,[2]],
		"/[ressourceId]/teams/[id]": [13,[2]],
		"/[ressourceId]/vendors": [14,[2]],
		"/[ressourceId]/vendors/[id]": [15,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';